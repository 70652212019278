import styled from 'styled-components';

const Container = styled.div`
  /* display: ${(props) => (props.isShowCarousel ? 'block' : 'none')}; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-image: url(${(props) =>
    props.toPath ? require('../../assets/images/bg.png') : ''});
  background-size: cover;
`;

export default Container;
