import { useEffect } from 'react';

function useChangeLinear() {
  useEffect(() => {
    const ele = document.querySelector('.navigator-bar');
    setTimeout(() => {
      ele.style.backgroundImage = `linear-gradient(
        -180deg,
           #060b73 0%,
          rgba(6, 14, 120, 0.8) 30%,
           rgba(7, 29, 143, 0) 100%
        )`;
    }, 0);
    return () => {
      ele.style.backgroundImage = 'none';
    };
  }, []);
}

export default useChangeLinear;
