import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  transition: all 0.1s ease-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
`;
const Image = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
const Title = styled.div`
  font-family: 'PingFangSC-Semibold';
  font-size: 1rem;
  color: #FFFFFF;
  text-align: center;
  position: absolute;
  left 50%;
  top: 50%;
  user-select:none;
  transform: translateX(-50%) translateY(-50%);
`;
export default ({ width, height, src, title, position, index }) => (
  <Container position={position}>
    <Title>{title}</Title>
    {/* <div>{index}</div>
    <div>{position}</div> */}
    <Image width={width} height={height} src={src}></Image>
  </Container>
);
