import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as THREE from 'three';
import * as types from '@/store/action-types.js';
import { CSS3DRenderer, CSS3DObject } from 'three-css3drenderer';
import { useWindowSize } from '../../hooks';
import { StyledContainer } from '../../pages/commonStyle';
import Container from './Container';
import SphereButton from './SphereButton';
import sphereTexture from '../../assets/images/sphere_texture.png';
import queryString from "query-string";
function historyPushAddQuery(history,pathname,...rest) {
  const type=queryString.parse(window.location.search).type
  console.log('type',type)
  if(type){
    history.push({pathname,search: `?type=${type}`},...rest)
  }else{
    history.push(pathname,...rest);
  }
}
const SUB_PRODUCT_MENUS = [
  '/product-service/financial',
  '/product-service/industry',
  '/product-service/public',
];
const SPHERE_WITH_SCALE = 450 / 1920;
const SPHERE_AREA_WIDTH_SCALE = (450 + 164) / 1920;
const getNavigation = (routeList = []) =>
  routeList
    .filter(
      (route) => route.meta && route.meta.level === 1 && route.meta.inCarousel
    )
    .map((route) => ({
      title: route.meta.name,
      path: route.path,
    }))
    .reverse();

const NavCarousel = ({
  routeList,
  fromPath,
  toPath,
  setShowCarousel,
  changeRedirectRelatedPaths,
}) => {
  const curPath = window.location.pathname;
  let hasInit = false;
  let inAppear = false;
  let rAFId = null;
  const history = useHistory();
  const targets = [];
  const navigation = getNavigation(routeList);
  const { width, height } = useWindowSize();
  const location = useLocation();
  const containerRef = useRef(null);

  const renderer = new CSS3DRenderer();
  const camera = new THREE.PerspectiveCamera(60, width / height);
  const scene = new THREE.Scene();
  const sphereWidth = width * SPHERE_WITH_SCALE;
  const circleRadius =
    (width * SPHERE_AREA_WIDTH_SCALE) / (Math.PI / navigation.length);

  // 目前是6个菜单项 重复两次得到循环
  const sphereQueue = [...navigation, ...navigation].map(
    ({ title, path }, index) => ({
      src: sphereTexture,
      position: (index * Math.PI) / navigation.length,
      title,
      path,
      index,
    })
  );
  const addSphereToScene = (queue) => {
    queue.forEach((item, index) => {
      const sphereContainer = document.createElement('div');

      // 兼容Firefox
      sphereContainer.style.pointerEvents = 'auto';
      ReactDOM.render(
        <SphereButton
          width={sphereWidth}
          height={sphereWidth}
          src={item.src}
          title={item.title}
          position={item.position}
          index={item.index}
        ></SphereButton>,
        sphereContainer
      );

      const object = new CSS3DObject(sphereContainer);

      // if (index >= navigation.length && !hasInit) {
      //   object.element.style.display = 'none';
      // }

      sphereContainer.style.transition = 'all .5s ease-out';
      object.position.setFromSphericalCoords(
        circleRadius,
        Math.PI / 2,
        item.position
      );
      object.lookAt(0, 0, 0);
      scene.add(object);
      targets.push(object);
    });
  };
  const adjustOpacity = () => {
    targets.forEach((object, index) => {
      const container = object.element;
      const objectPositionVector3 = new THREE.Vector3();
      const cameraLookVector3 = new THREE.Vector3();

      object.getWorldPosition(objectPositionVector3);
      camera.getWorldDirection(cameraLookVector3);
      const angleOffset =
        cameraLookVector3.angleTo(objectPositionVector3) % (Math.PI * 2);
      if (Math.abs(angleOffset) > Math.PI / sphereQueue.length) {
        container.style.filter = 'brightness(0.8)';
        container.style.opacity = 0.5;
      } else {
        container.style.filter = '';
        container.style.opacity = 1;
      }
    });
  };
  const runScale = (item, index) => {
    const direction = new THREE.Vector3();
    const origin = new THREE.Vector3(0, 0, 0);

    // if (!hasInit) {
    //   return;
    // }
    if (rAFId) {
      requestAnimationFrame(() => runScale(item, index));
      return;
    }
    if (targets[index].element && containerRef.current) {
      containerRef.current.style.opacity =
        1 - camera.position.distanceTo(origin) / circleRadius;
    }
    if (camera.position.distanceTo(origin) < circleRadius) {
      camera.getWorldDirection(direction);
      camera.position.addScaledVector(direction, 30);
      renderer.render(scene, camera);
      requestAnimationFrame(() => runScale(item, index));
    } else {
      // updatePath(navigation[index % navigation.length].path);
      // updatePath(toPath);
      setShowCarousel(false);
      if (SUB_PRODUCT_MENUS.includes(curPath) && curPath !== toPath) {
        historyPushAddQuery(history,toPath.match(/\/([\w-]+)/)[0], { redirectMenu: toPath });
      } else {
        historyPushAddQuery(history,toPath);
      }
      changeRedirectRelatedPaths({ fromPath: toPath, toPath: '' });
      // setTimeout(() => {
      //   document.querySelector(
      //     '.navigator-bar'
      //   ).style.backgroundImage = `linear-gradient(
      //     -180deg,
      //        #060b73 0%,
      //       rgba(6, 14, 120, 0.8) 30%,
      //        rgba(7, 29, 143, 0) 100%
      //     )`;
      // }, 0);
    }
  };
  const runAppear = (initialed) => {
    const direction = new THREE.Vector3();
    const origin = new THREE.Vector3(0, 0, 0);

    if (!initialed) {
      camera.getWorldDirection(direction);
      camera.position.addScaledVector(direction, circleRadius);
      inAppear = true;
    }

    if (camera.position.distanceTo(origin) > 100) {
      camera.getWorldDirection(direction);
      camera.position.addScaledVector(direction, -40);
      requestAnimationFrame(() => runAppear(true));
    } else {
      camera.position.set(0, 0, 0);
      inAppear = false;
    }
    if (containerRef && containerRef.current) {
      const opacity =
        Math.abs(circleRadius - camera.position.distanceTo(origin)) /
        circleRadius;

      containerRef.current.style.opacity = opacity < 0.8 ? opacity : 1;
    }
    adjustOpacity();
    // adjustProcess();
    renderer.render(scene, camera);
  };
  const runRotate = (target, index, s) => {
    const targetY = sphereQueue[index].position;
    const angleOffset = targetY - camera.rotation.y;
    const speed = s || 0.01;
    const direction = angleOffset > 0 ? 1 : -1;
    if (rAFId) {
      cancelAnimationFrame(rAFId);
      rAFId = null;
    }
    if (inAppear) {
      rAFId = requestAnimationFrame(() => runRotate(target, index, s));
      return;
    }
    if (Math.abs(angleOffset) > speed) {
      camera.rotation.y += speed * direction;
      rAFId = requestAnimationFrame(() => runRotate(target, index, s));
    } else {
      // console.log('finish');
      // cancelAnimationFrame(rAFId);
      camera.rotation.y = sphereQueue[index].position;
      runScale(target, index);
      // if (/(\/[\w-]+){2}/.test(toPath)) {
      //   history.push(toPath);
      // }
      // history.push(sphereQueue[index]['path']);
    }
    adjustOpacity();
    renderer.render(scene, camera);
  };
  const toIndex =
    toPath &&
    navigation.findIndex(
      (n) =>
        n.path === toPath ||
        (toPath && toPath.match(/\/([\w-]+)/)[0] === n.path)
    );
  const toIndexArr = sphereQueue.filter(
    (item) =>
      item.path === toPath ||
      (toPath.match(/\/([\w-]+)/) &&
        toPath.match(/\/([\w-]+)/)[0] === item.path)
  );

  let curIndex = navigation.findIndex((n) => {
    return (
      n.path === fromPath ||
      (fromPath !== '/' && fromPath.match(/\/([\w-]+)/)[0] === n.path)
    );
  });
  const fromIndexArr = sphereQueue.filter((item) => item.path === fromPath);
  const withoutAnime = location.state && location.state.withoutAnime;
  if (withoutAnime) {
    camera.rotation.y = (5 * Math.PI) / navigation.length;
  }
  if (curIndex > -1) {
    if (curIndex === 0) {
      curIndex = 6;
    }
    camera.rotation.y = sphereQueue[curIndex]['position'];
  }
  renderer.setSize(width, height);
  camera.position.x = 0;
  camera.position.z = 0;
  addSphereToScene(sphereQueue);

  useEffect(() => {
    const container = document.getElementById('carousel-cartoon-com');
    // if (withoutAnime) {
    //   adjustOpacity();
    // }
    if (!toPath && withoutAnime) {
      runAppear();
    }
    if (fromPath === '/') {
      camera.rotation.y = (5 * Math.PI) / navigation.length;
    }
    if (fromPath !== toPath && toIndex > -1) {
      if (fromPath !== '/') {
        runAppear();
      }
      let toNearPoItem;
      if (toIndexArr && toIndexArr.length === 2) {
        const cameraPos = camera.rotation.y;
        toNearPoItem = toIndexArr.reduce(
          (obj, item) => {
            if (Math.abs(item.position - cameraPos) < Math.abs(obj.offset)) {
              obj = {
                index: item.index,
                offset: item.position - cameraPos,
                position: item.position,
              };
            }
            return obj;
          },
          { index: 100, offset: 100, position: 100 }
        );
      }
      // runRotateNear(toNearPoItem, 0.02);
      toNearPoItem &&
        runRotate(targets[toNearPoItem.index], toNearPoItem.index, 0.02);
      // runScale(targets[toIndex], toIndex);
      // runScale(targets[toNearPoItem.index], toNearPoItem.index);
    }

    // 兼容Firefox
    if (renderer.domElement && renderer.domElement.childNodes) {
      renderer.domElement.childNodes[0].style.pointerEvents = 'none';
    }
    if (container) {
      container.appendChild(renderer.domElement);
      renderer.render(scene, camera);
    }
    return () => container && container.removeChild(renderer.domElement);
  });

  return (
    <Container toPath={toPath} ref={containerRef}>
      <StyledContainer
        id="carousel-cartoon-com"
        size={{ width, height }}
      ></StyledContainer>
    </Container>
  );
};

// export default NavCarousel;

const mapStateToProps = (state) => {
  return {
    fromPath: state.common.redirectRelativePaths.fromPath,
    toPath: state.common.redirectRelativePaths.toPath,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setShowCarousel: (bol) =>
      dispatch({ type: types.CHANGE_SHOW_CAROUSE_ANIMATION, data: bol }),
    changeRedirectRelatedPaths: (data) =>
      dispatch({ type: types.REDIRECT_RELATE_PATHS, data }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(NavCarousel));
