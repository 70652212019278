import * as types from '../action-types.js';
const initState = {
  isSHowCarousel: false,
  fromComponyTrendInfo: false,
  redirectRelativePaths: {
    fromPath: window.location.pathname,
    toPath: '',
  },
};
export default (state = initState, { type, data }) => {
  switch (type) {
    case types.CHANGE_SHOW_CAROUSE_ANIMATION:
      return {
        ...state,
        isSHowCarousel: data,
      };
      break;
    case types.FROM_COMPONY_TREND_INFO:
      return {
        ...state,
        fromComponyTrendInfo: data,
      };
    case types.REDIRECT_RELATE_PATHS:
      return {
        ...state,
        redirectRelativePaths: {
          ...state.redirectRelativePaths,
          ...data,
        },
      };
    default:
      return state;
      break;
  }
};
