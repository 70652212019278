import React from 'react';
import NavigationBar from '../NavigationBar';

const Navigation = () => {
  return (
    <div>
      <NavigationBar />
    </div>
  );
};

export default Navigation;
