import React ,{useEffect,useCallback} from 'react';
import { BrowserRouter as Router, Switch, Route, Link ,useHistory,Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as types from '@/store/action-types.js';
// import store from '@/store';
import RouteConfig from './routeConfig/router.js';
import Navigation from './component/Navigation';
import BodyBackground from './component/bodyBackground';
import queryString from 'query-string';
import judgEenvironment, {codes} from '@/utils/judgEenvironment'

const wrapRouteComponent = (WrappedComponent, isShowCarousel) => () => (
  <div>
    <BodyBackground></BodyBackground>
    <Navigation></Navigation>
    {!isShowCarousel && <WrappedComponent></WrappedComponent>}
  </div>
);
const trackInit = () => {
  const env = judgEenvironment()
  const getScriptSrc = (scriptCode) => {
    var span = document.createElement('span')
    span.innerHTML = scriptCode
    return span.getElementsByTagName('script')[0].src
  }
  const code = codes[env]
  const script = document.createElement('script')
  script.src = getScriptSrc(code)
  script.language = 'JavaScript'
  document.body.appendChild(script)
}
const App = (props) => {
  const { isShowCarousel, setShowCarousel } = props;
  const history = useHistory();
  let originUrl=window.location.href
  useEffect(()=>{
    trackInit()
    history.listen((location, action) => {
      if (window._czc) {
        let location = window.location
        let contentUrl = location.pathname + location.search + location.hash
        window._czc.push(['_trackPageview', contentUrl, originUrl])
      }

      originUrl=window.location.href
    })
  },[])
/*  useEffect(()=>{

    console.log('before route change')
  },[window.location.href])*/
  console.log('pppp',history)
  return (
    // <Provider store={store}>
      <Switch>
        {RouteConfig.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={
              route.path === '/animate-demo'
                ? () => <route.component />
                : wrapRouteComponent(route.component, isShowCarousel)
            }
          />
        ))}
        <Redirect from="*" to="/"></Redirect>
      </Switch>
    // </Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    isShowCarousel: state.common.isSHowCarousel,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setShowCarousel: (bol) =>
      dispatch({ type: types.CHANGE_SHOW_CAROUSE_ANIMATION, data: bol }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
