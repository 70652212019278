import { useEffect, useReducer } from 'react';

function useChangeIndex(params, listFresh) {
  const reducer = function (state, action) {
    switch (action.type) {
      case 'next':
        return {
          ...state,
          last: state.last + 1,
        }
      case 'pre':
        return {
          ...state,
          last: state.last - 1,
        }
      default:
        return state
    }
  }
  const [state, dispatch] = useReducer(reducer, params)
  useEffect(() => {
    listFresh(state)
  }, [state])
  return [state, dispatch]
}

export default useChangeIndex;
