import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: ${(props) => props.size.width}px;
  height: ${(props) => props.size.height}px;
  /* background-image: ${(props) => `url(${props.bgImg})`}; */
  background-size: cover;
  /* display: flex; */
  /* flex-direction: column; */
  /* #cartoon {
    height: 15rem;
    flex: 1;
  } */
  .control-btn {
    /* flex-basis: 3rem; */
    margin-bottom: 0.5rem;
  }
`;
