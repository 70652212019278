import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore, useDispatch, connect } from 'react-redux';
import NavCarousel from '@/component/NavCarousel';
import classnames from 'classnames';
import queryString from 'query-string'

import routeList from '@/routeConfig/router';
import * as types from '@/store/action-types.js';
import styled from 'styled-components';
import './index.less';

function historyPushAddQuery(history,pathname,...rest) {
  const type=queryString.parse(window.location.search).type
  if(type){
    history.push({pathname,search: `?type=${type}`},...rest)
  }else{
    history.push(pathname,...rest);
  }
}
const SUB_PRODUCT_MENUS = [
  '/product-service/financial',
  '/product-service/industry',
  '/product-service/public',
];

const NavigatorBar = (props) => {
  const {
    isShowCarousel,
    setShowCarousel,
    redirectRelativePaths,
    changeRedirectRelatedPaths,
  } = props;
  const curPath = window.location.pathname;
  const history = useHistory();

  const changeNav = (path) => {
    if (
      SUB_PRODUCT_MENUS.includes(curPath) &&
      path.path === '/product-service'
    ) {
      historyPushAddQuery(history,path.path);
    } else {
      changePath(path);
    }
  };
  const changePath = (targetRoute) => {
    debugger
    if (targetRoute.path === '/' && targetRoute.path !== curPath) {
      // document.querySelector('.navigator-bar').style.backgroundImage = 'none';
      historyPushAddQuery(history,'/', { withoutAnime: true });
      changeRedirectRelatedPaths({ fromPath: '/', toPath: '' });
    } else if (targetRoute.path !== curPath) {
      changeRedirectRelatedPaths({
        toPath: targetRoute.path,
        fromPath: curPath,
      });
      // document.querySelector('.navigator-bar').style.backgroundImage = 'none';
      setShowCarousel(true);
    }
  };
  const routerLink = (e, path) => {
    e.stopPropagation();
    changeRedirectRelatedPaths({ toPath: path });
    if (SUB_PRODUCT_MENUS.includes(path) && curPath === '/product-service') {
      // 有坑，react按照变量获取
      switch (path) {
        case '/product-service/financial':
          document.querySelector('#sub-course-2').click();
          break;
        case '/product-service/industry':
          document.querySelector('#sub-course-1').click();
          break;
        case '/product-service/public':
          document.querySelector('#sub-course-0').click();
          break;
        default:
          break;
      }
      return;
    }
    if (SUB_PRODUCT_MENUS.includes(curPath) && curPath !== path) {
      historyPushAddQuery(history,path.match(/\/([\w-]+)/)[0], {
        redirectMenu: path,
        fromSubPath: curPath,
      });
    } else {
      setShowCarousel(true);
    }
  };
  return (
    <div
      className="navigator-bar"
      //   style={{
      //     backgroundImage: isShowCarousel
      //       ? ''
      //       : `linear-gradient(
      //   -180deg,
      //   #060b73 0%,
      //   rgba(6, 14, 120, 0.8) 30%,
      //   rgba(7, 29, 143, 0) 100%
      // )`,
      //   }}
    >
      <div className="navigator-wrapper">
        {routeList
          .filter((route) => route.meta && route.meta.level === 1)
          .map((route, index) => {
            // const activated = props.fromComponyTrendInfo
            //   ? curPath.split('/')[1] === '/company-trends'
            //   : route.path === curPath ||
            //     `/${curPath.split('/')[1]}` === route.path;
            const activated =
              route.path === curPath ||
              `/${curPath.split('/')[1]}` === route.path;
            return (
              <>
                <div
                  className={`
                    navigator-bar-item
                    ${activated ? 'navigator-bar-active' : ''}
                  `}
                  key={index}
                >
                  {route.meta.src ? (
                    <img
                      onClick={() => changeNav(route)}
                      src={route.meta.src}
                      alt=""
                    />
                  ) : (
                    <>
                      <div
                        className="navigation-item-title"
                        onClick={() => changeNav(route)}
                        className={classnames('navigation-item-title', {
                          'navigation-item-title-active': !!activated,
                        })}
                      >
                        {route.meta.name}
                      </div>
                      {!!activated && (
                        <div className="active-navigation-bar"></div>
                      )}
                      {!!route.children && (
                        <div className="navigation-sub-menu">
                          {route.children.map((item) => (
                            <div
                              className={classnames(
                                'navigation-sub-menu-item',
                                {
                                  'navigation-sub-menu-item-active':
                                    item.path === curPath,
                                }
                              )}
                              onClick={(e) => routerLink(e, item.path)}
                            >
                              {item.meta.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            );
          })}
      </div>
      {isShowCarousel && <NavCarousel routeList={routeList}></NavCarousel>}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isShowCarousel: state.common.isSHowCarousel,
    redirectRelativePaths: state.common.redirectRelativePaths,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setShowCarousel: (bol) =>
      dispatch({ type: types.CHANGE_SHOW_CAROUSE_ANIMATION, data: bol }),
    changeRedirectRelatedPaths: (data) =>
      dispatch({ type: types.REDIRECT_RELATE_PATHS, data }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(NavigatorBar));
