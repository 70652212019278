import React from 'react';
import styled from 'styled-components';
import { useWindowSize } from '../../hooks';
import video from './background.mp4';

const BgWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index:-999;
  video {
    object-fit: fill;
  }
`;

const BodyBackground = () => {
  const { width, height } = useWindowSize();

  return (
    <BgWrapper>
      <div style={{ overFlow: 'hidden' }}>
        <video
          style={{ width, height }}
          loop
          muted
          autoPlay={true}
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </BgWrapper>
  );
};

export default BodyBackground;
