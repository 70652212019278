const codes={
  'prodAi':`<script type="text/javascript" src="https://s4.cnzz.com/z_stat.php?id=1279166102&web_id=1279166102"></script>`,
  'prodCn':`<script type="text/javascript" src="https://s4.cnzz.com/z_stat.php?id=1279129262&web_id=1279129262"></script>`,
  'testAi':`<script type="text/javascript" src="https://s9.cnzz.com/z_stat.php?id=1279165100&web_id=1279165100"></script>`,
  'testCn':`<script type="text/javascript" src="https://v1.cnzz.com/z_stat.php?id=1279129297&web_id=1279129297"></script>`
}
function judgEenvironment() {
  const hostname=window.location.hostname
  const types={
    'www.real-ai.cn':'prodCn',
    'real-ai.cn':'prodCn',
    'wwww.realai.ai':'prodAi',
    'realai.ai':'prodAi',
    'test-www.real-ai.cn':'testCn',
    'test-www.realai.ai':'testAi',
  }
  return types[hostname]||'testCn'
}
export {codes}
export default judgEenvironment
