import Loadable from 'react-loadable';
import logoPic from '../assets/images/logo.png';

const AsyncLoadable = (opts) => {
  return Loadable({
    loading: () => null,
    // loading: () => import('../component/Loading/index'),
    // loading: Loading,
    // delay: 8000,
    // timeout: 1000,
    ...opts,
  });
};

// 路由及顶部导航所有信息，顺序很重要，会影响到导航栏的展示顺序
export default [
  {
    path: '/home-page',
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/HomePage/index'),
    }),
    meta: {
      level: 1,
      name: '首页',
      inCarousel: true,
    },
  },
  {
    path: '/technology',
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/Technology/Technology.js'),
    }),
    meta: {
      level: 1,
      name: '核心技术',
      inCarousel: true,
    },
  },
  {
    path: '/product-service',
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/ProductService/SubCarousel'),
    }),
    meta: {
      level: 1,
      name: '产品服务',
      inCarousel: true,
    },
    children: [
      {
        path: '/product-service/financial', //金融
        exact: true,
        component: AsyncLoadable({
          loader: () => import('../pages/Financial/Financial'),
        }),
        meta: {
          level: 2,
          name: '金融',
        },
      },
      {
        path: '/product-service/industry', //工业
        exact: true,
        component: AsyncLoadable({
          loader: () => import('../pages/Industry/index'),
        }),
        meta: {
          level: 2,
          name: '工业',
        },
      },
      {
        path: '/product-service/public', // 公共服务
        exact: true,
        component: AsyncLoadable({
          loader: () => import('../pages/PublicService/PublicService'),
        }),
        meta: {
          level: 2,
          name: '公共服务',
        },
      },
    ],
  },
  {
    path: '/',
    exact: true,
    // component: () => null,
    component: AsyncLoadable({
      // loader: () => import('../pages/AnimateDemo'),
      loader: () => import('../pages/Menu'),
    }),
    meta: {
      level: 1,
      name: '过渡页',
      src: logoPic,
    },
  },
  {
    path: '/about-us',
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/AboutUs/index'),
    }),
    meta: {
      level: 1,
      name: '关于我们',
      inCarousel: true,
    },
  },
  {
    path: '/company-trends',
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/CompanyTrends/index'),
    }),
    meta: {
      level: 1,
      name: '公司动态',
      inCarousel: true,
    },
  },
  {
    path: '/join-us',
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/JoinUs/index'),
    }),
    meta: {
      level: 1,
      name: '加入我们',
      inCarousel: true,
    },
  },
  {
    path: '/company-trends/info/:id', //动态详情
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/Info/Info.js'),
    }),
  },
  {
    path: '/join-us/position/:id', //职位详情
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/Position/Position.js'),
    }),
  },
  {
    path: '/product-service/financial', //金融
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/Financial/Financial'),
    }),
  },
  {
    path: '/product-service/industry', //工业
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/Industry/index'),
    }),
  },
  {
    path: '/product-service/public', // 公共服务
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/PublicService/PublicService'),
    }),
  },
  {
    path: '/public-service', // 公共服务
    exact: true,
    component: AsyncLoadable({
      loader: () => import('../pages/PublicService/PublicService'),
    }),
  },
/*  {
    path: '/animate-demo', // demo
    exact: true,
    component: AsyncLoadable({
      // loader: () => import('../pages/AnimateDemo'),
      loader: () => import('../pages/AnimateDemo/AnimateDemo'),
    }),
  },*/
  {
    path: '/menu', // 公共服务
    exact: true,
    component: AsyncLoadable({
      // loader: () => import('../pages/AnimateDemo'),
      loader: () => import('../pages/Menu'),
    }),
  },
];
