export const TEST_ACTION = 'TEST_ACTION';
export const ADD_NUMBER = 'ADD_NUMBER';
export const RESET_NUMBER = 'RESET_NUMBER';
export const PATCH_UPLOAD_STATUS = 'PATCH_UPLOAD_STATUS';

export const CHANGE_SHOW_CAROUSE_ANIMATION = 'CHANGE_SHOW_CAROUSE_ANIMATION';

export const FROM_COMPONY_TREND_INFO = 'FROM_COMPONY_TREND_INFO';

export const REDIRECT_RELATE_PATHS = 'REDIRECT_RELATE_PATHS';
